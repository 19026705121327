/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2022-10-05-github-availability-report-september-2022/"
  }, "GitHub Availability Report: September 2022 - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hacktoberfest.com/"
  }, "Hacktoberfest 2022")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/dan_abramov/status/1311692006775758853"
  }, "@dan_abramov - Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/pokecoder/status/1570867285296050176"
  }, "틱톡 Kubernetes 영상 - Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.netflix.com/ko/news/announcing-basic-with-ads-ko"
  }, "월 5,500원으로 만나는 넷플릭스 - About Netflix")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/it/article/202210147123i"
  }, "넷플릭스 광고 요금제 월 5500원…콘텐츠 중 10는 못봐 - 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://domains.ray.st/"
  }, "빠른 도메인 검색 서비스")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
